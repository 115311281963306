<template>
  <div class="mySwiper">
    <swiper class="swiper" :options="swiperOption">
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <swiper-slide
        class="swiper_slider"
        v-for="(item,index) in videoList"
        :key="index"
        :style="{'width':(nowWidth < 980 ? '250px':'300px'),'height':(nowWidth < 980 ? '230px':'250px')}"
      >
        <div
          class="videoitem"
          @mouseover="mouseOver(index)"
          @mouseleave="mouseLeave(index)"
          @click="pickVideo(item,index)"
        >
          <Card class="card-style" style="width:100%;">
            <div style="text-align:center">
              <div class="video-img">
                <div class="player-btn-mask" v-show="maskFlag != null && maskFlag == index">
                  <img src="../../assets/images/player.png" alt />
                </div>
                <img :src="require('../../assets/images/'+item.imgSrc+'.png')" alt />
              </div>
              <div class="card-hr"></div>
              <div class="card-title" :style="{'font-size':(nowWidth < 980 ? '14px':'16px')}" >{{item.name}}</div>
              <div class="card-info" :style="{'font-size':(nowWidth < 980 ? '12px':'14px')}">抖工拍摄团队</div>
              <!-- <div class="card-desc">{{item.desc}}</div> -->
            </div>
          </Card>
        </div>
      </swiper-slide>
      <!-- <swiper-slide class="swiper_slider">
      <div class="videoitem">
        <Card class="card-style" style="width:100%;">
          <div style="text-align:center">
            <div class="video-img"><img src="../../assets/images/videoImg2.png" alt=""></div>
            <div class="card-title">视频二</div>
          </div>
        </Card>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper_slider">
      <div class="videoitem">
        <Card class="card-style" style="width:100%;">
          <div style="text-align:center">
            <div class="video-img"><img src="../../assets/images/videoImg3.png" alt=""></div>
            <div class="card-title">视频三</div>
          </div>
        </Card>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper_slider">
      <div class="videoitem">
        <Card class="card-style" style="width:100%;">
          <div style="text-align:center">
            <div class="video-img"><img src="../../assets/images/videoImg4.png" alt=""></div>
            <div class="card-title">视频四</div>
          </div>
        </Card>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper_slider">
      <div class="videoitem">
        <Card class="card-style" style="width:100%;">
          <div style="text-align:center">
            <div class="video-img"><img src="../../assets/images/videoImg5.png" alt=""></div>
            <div class="card-title">视频五</div>
          </div>
        </Card>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper_slider">
      <div class="videoitem">
        <Card class="card-style" style="width:100%;">
          <div style="text-align:center">
            <div class="video-img"><img src="../../assets/images/videoImg6.png" alt=""></div>
            <div class="card-title">视频六</div>
          </div>
        </Card>
      </div>
      </swiper-slide>-->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-example-free-mode",
  title: "Free mode / No fixed positions",
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['nowWidth'],
  data() {
    return {
      swiperOption: {
        // slidesPerView: 3,
        slidesPerView: "auto",
        spaceBetween: 15,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      videoList: [
        { name: "抖工安教应用案例介绍", imgSrc: "fzpt_img", desc: `《中华人民共和国安全生产法》中第二十五条要求：“未经安全生产教育和培训合格的从业人员，不得上岗作业”。
抖工安教，结合生物特征识别等先进技术只需手机简单操作，就能随时随地完成实名安全培训并无缝对接考勤，优化工作流程。
安全教育培训从此更加合法合规、有效真实、文明且绿色。` },
        { name: "抖工微课程拍摄快闪", imgSrc: "videoImg1", desc: "微课课程快闪剪辑。" },
        { name: "抖工样板课程快闪 - 高处作业", imgSrc: "videoImg2", desc: "凡在坠落高度基准面2m或2m以上有可能坠落的高处进行作业，都称为高处作业。当作业中工作面的边沿没有围护设施或围护设施的作业称为临边作业。"  },
        { name: "抖工样板课程混剪 - 临时用电", imgSrc: "videoImg3", desc: "“电”是现代建筑施工越来越广泛使用的二次能源。临时用电存在着很大的危险性，稍不注意，就会造成人员伤亡。临时用电的随意性、非规范性现象比较突出，这也是造成事故频发的主要原因。"  },
        { name: "安全色与安全标记 - 禁电类", imgSrc: "videoImg4", desc: "安全色就是传递安全信息含义的颜色，包括红色、蓝色、黄色、绿色。红色传递禁止、停止、危险或提示消防设备、设施的信息。蓝色传递必须遵守规定的指令性信息。黄色传递注意、警告的信息。绿色传递安全的提示性信息。"  },
        { name: "高处作业--危险因素", imgSrc: "videoImg5", desc: "高处作业的危险因素主要有：人的（危险）行为、物的（危险）因素、环境的（危险）因素、管理的（危险）方面。"  },
        { name: "建设工程安全生产管理条例", imgSrc: "videoImg6", desc: "施工单位若存在安全防护用具、机械设备、施工机具及配件在进入施工现场前未经查验或者查验不合格即投入使用，或使用未经验收或者验收不合格的机械和脚手架、模板等自升式架设设施。该负责任为：限期改正或责令停业整顿，并处10万元以上30万元以下的罚款。情节严重的构成犯罪的，对直接责任人员追究刑事责任。"  }
      ],
      maskFlag: null
    };
  },
  methods: {
    pickVideo(val, idx) {
      this.$emit("changeVideo", val, idx);
    },
    mouseOver(idx) {
      if (this.maskFlag == null) {
        this.maskFlag = idx;
        // console.log(idx)
      }
    },
    mouseLeave(idx) {
      this.maskFlag = null;
      // console.log(idx)
    }
  }
};
</script>
<style scoped>
.swiper >>> .ivu-card-body {
  padding: 1px;
}
::-webkit-scrollbar {
display: none;
}
.scrollbar{
  display: none;
}
.swiper-button-prev{

}
</style>
<style lang="scss" scoped>
.mySwiper{
  position: relative;
  .swiper-button-next{
    right: 0px;
    color: #fff;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
  .swiper-button-prev{
    left: 0px;
    color: #fff;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 30px;
  }
}
.swiper {
  width: 100%;
  padding: 10px 0;
  // height: 350px;
  .swiper_slider {
    width: 300px;
    // height: 300px;
    height: 250px;
  }
  .p-slider{
    width: 250px !important;
  }
}
.videoitem {
  width: 100%;
  height: 100%;
  .card-style {
    width: 100%;
    height: 100%;
  }
  .video-img {
    width: 100%;
    position: relative;
    padding: 5px;
    // .player-btn-mask:hover{
    //   display: block;
    // }
    .player-btn-mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      img {
        width: 30%;
        height: auto;
        opacity: 0.3;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .card-title {
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    padding: 0 20px 0 20px;
    color: #222;
  }
  .card-info{
    width: 100%;
    text-align: left;
    padding: 0 20px;
    margin: .5em 0;
    color: #999;
  }
  .card-desc{
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 20px;
    text-align: left;
    margin: .7em 0;
  }
  .card-hr{
    margin: .5em auto;
    // margin: .5em 0 .5em 20px;
    width: 25%;
    height: 1px;
    background: #00c9ff;
  }
  // background: red;
}
</style>